<template>
    <div class="row">
        <div class="col-12 g" v-for="service in services" :key="service._id">
            <div class="card">
                <div class="card-header">
                    <p style="width: 100%">
                        العنوان
                        <input type="text" v-model="service.title" class="form-control form-control-lg">
                        <br>الوصف
                        <input type="text" v-model="service.bio" class="form-control form-control-lg">
                        <br>السعر
                        <input type="text" v-model="service.price" class="form-control form-control-lg" placeholder="السعر هنا... (كتابة)">
                        <br>
                        <select name="" v-model="service.have_demo" id="">
                            <option value="yes">متاح تجربة (نعم)</option>
                            <option value="no">متاح تجربة (لا)</option>
                        </select>
                    </p>
                </div>
                <div class="card-footer">
                    <p>
                        الاسعار <button class="btn btn-sm btn-primary" style="border-radius: 50%; padding: 5px;" @click="service.plans.push({
                            code: Math.random(),
                            title: '',
                            amount: 1,
                            per: 'all',
                            days: 30
                        })"><i class="fa fa-plus"></i></button>
                    </p>
                    <div class="col-12 table-responsive" style="min-height: auto">
                        <table class="table table-bordered table-sm">
                            <thead>
                                <th>
                                    العنوان
                                </th>
                                <th>
                                    السعر
                                </th>
                                <th>
                                    السعر
                                    لكل
                                </th>
                                <th>
                                    عدد الايام
                                </th>
                            </thead>
                            <tbody>
                                <tr v-for="p in service.plans" :key="p.code">
                                    <td>
                                        <input type="text" class="form-control" v-model="p.title" placeholder="مثلا: اشتراك عام دراسي">
                                    </td>
                                    <td>
                                        <input type="number" class="form-control" v-model="p.amount">
                                    </td>
                                    <td>
                                        <select name="" class="form-control" v-model="p.per" id="">
                                            <option value="all">تكلفة اجمالية</option>
                                            <option value="student">لكل طالب</option>
                                            <option value="teacher">لكل معلم</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="number" class="form-control" v-model="p.days">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    المميزات
                    <ckeditor :editor="editor" v-model="service.content" :config="editorConfig"></ckeditor>
                </div>
            </div>
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-success" @click="save()">حفظ التعديلات</button>
        </div>
    </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: 'app',
    data() {
        return {
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {},
            services: [],
            jwt: localStorage.getItem('jwt'),
        };
    },
    created(){
        var g = this;
        $.post(api + '/admin/settings/services', {
            jwt: this.jwt,
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.services = r.response
            }
        }).fail(function(){
            g.loading = false;
        })
    },
    methods: {
        save(){
            var g = this;
            $.post(api + '/admin/settings/services/save', {
                jwt: this.jwt,
                services: g.services
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    alert("<h2>اعمل ريستارت بقا للpm2</h2>", 200)
                }
            }).fail(function(){
                g.loading = false;
            })
        }
    }
}
</script>

<style>

</style>